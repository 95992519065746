/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    code: "code",
    h3: "h3",
    ol: "ol",
    li: "li",
    pre: "pre",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "prefix-sum-array",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prefix-sum-array",
    "aria-label": "prefix sum array permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Prefix Sum Array"), "\n", React.createElement(_components.p, null, "and their interesting properties along with implementation in Rust Language"), "\n", React.createElement(_components.h2, {
    id: "introduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#introduction",
    "aria-label": "introduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Introduction"), "\n", React.createElement(_components.p, null, "Prefix Sum Array is widely used in Competitive Programming for optimizing the solution, generally in arrays or vectors.\nUsing Prefix Sum Array, we can perform Range Queries in ", React.createElement(_components.strong, null, "constant, or O(1)"), " time complexity."), "\n", React.createElement(_components.p, null, "In this article, we will discuss some interesting properties of prefix sum array, and see the code in Rust Language to build a prefix sum array."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Definition :"), " Prefix sum array, ", React.createElement(_components.code, null, "p"), " of an array ", React.createElement(_components.code, null, "a"), " is defined such that every index of array ", React.createElement(_components.code, null, "p"), ", contains sum of all elements of ", React.createElement(_components.code, null, "a"), " upto that index."), "\n", React.createElement(_components.p, null, "Formally, for all ", React.createElement(_components.code, null, "0 <= i < N"), ","), "\n", React.createElement(_components.p, null, "p[i] = a[0] + a[1] + a[2]... + a[i-1] + a[i]"), "\n", React.createElement(_components.h3, {
    id: "interesting-properties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#interesting-properties",
    "aria-label": "interesting properties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Interesting properties"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "i", React.createElement("sup", null, "th"), " element of prefix sum array, p[i] = a[i]+p[i-1],  for all ", React.createElement(_components.code, null, "1 <= i < n")), "\n", React.createElement(_components.li, null, "Prefix sum array of positive elements is ", React.createElement(_components.strong, null, "always sorted"), ", in ascending order."), "\n", React.createElement(_components.li, null, "If there are common element in prefix array, this means that sum of the subarray between them is 0."), "\n"), "\n", React.createElement(_components.h2, {
    id: "generate-prefix-array",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#generate-prefix-array",
    "aria-label": "generate prefix array permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Generate Prefix Array"), "\n", React.createElement(_components.p, null, "Here is the function to generate a prefix array in Rust Language."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn prefix_sum(array:&Vec<usize>)->Vec<usize>{\n\n    // Initialize the array with all 0\n    let mut prefix_array = vec![0; array.len()];\n\n    // First element of prefix array is first element of array\n    prefix_array[0] = array[0];\n\n    // Generate prefix array using property\n    // p[i] = a[i] + p[i-1]\n    for i in 1..array.len() {\n        prefix_array[i] = prefix_array[i-1]+array[i];\n    }\n\n    return prefix_array;\n}\n")), "\n", React.createElement(_components.p, null, "With driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn prefix_sum(array:&Vec<usize>)->Vec<usize>{\n\n    // Initialize the array with all 0\n    let mut prefix_array = vec![0; array.len()];\n\n    // First element of prefix array is first element of array\n    prefix_array[0] = array[0];\n\n    // Generate prefix array using property\n    // p[i] = a[i] + p[i-1]\n    for i in 1..array.len() {\n        prefix_array[i] = prefix_array[i-1]+array[i];\n    }\n\n    return prefix_array;\n}\n\n// Driver code\n\nfn main() {\n    let arr = vec![1, 2, 3, 4, 5, 6, 7];\n    println!(\"{:?}\", prefix_sum(&arr));\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "[1, 3, 6, 10, 15, 21, 28]"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( n )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( n )")), "\n", React.createElement(_components.h2, {
    id: "uses-of-prefix-sum-array",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#uses-of-prefix-sum-array",
    "aria-label": "uses of prefix sum array permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Uses of Prefix Sum Array"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Range Queries :"), " By using prefix sum array, the Range Queries such as find sum of elements from [l, r] can be done in constant or ", React.createElement(_components.strong, null, "O(1)"), " time complexity."), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "Subarray with 0 sum :"), " In prefix array, if there is any repeating element, that means that the sum of that subarray is 0."), "\n"), "\n", React.createElement(_components.p, null, "and so on..."), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Prefix Sum Array is widely used in Competitive Programming for optimizing the solution, generally in arrays or vectors."), "\n", React.createElement(_components.p, null, "In this article, we discussed various properties and applications of prefix sum array and saw how to implement it in Rust Language."), "\n", React.createElement(_components.p, null, "Here is the function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn prefix_sum(array:&Vec<usize>)->Vec<usize>{\n    let mut prefix_array = vec![0; array.len()];\n    prefix_array[0] = array[0];\n    for i in 1..array.len() { prefix_array[i] = prefix_array[i-1]+array[i]; }\n    return prefix_array;\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Prefix Sum Array - Rust Programming",
    description: "Prefix sum array is frequently used to optimize the solution. In this article, we will see various properties and applications of prefix sum array and see how to implement it in Rust Language"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
